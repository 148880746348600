$( document ).ready(function() {
	// FUNCTIONS
    cf7formsubmit();
	$('a[data-rel^=lightcase]').lightcase();

	// WINDOW ON LOAD
	$(window).on("load", function(){
        primaryNavigation();
        caroufredsel_slider();
        offcanvasMenu();
        aboutTabs();
        treatmentTabs();
        _expandTabs();
        stickyHeader();

        // SITE LOADER
        $(".site-loader").css("opacity", 0);
        setTimeout(function(){
            $(".site-loader").css("z-index", -1);
        }, 300);
    });

    //WINDOW ON SCROLL
    $(window).scroll(function(){
        stickyHeader();
    });

	// WINDOW ON RESIZE
	$(window).on("resize", function(){
    	caroufredsel_slider();
      _expandTabs();

      // facebook page feed
    if(this.resizeTO) clearTimeout(this.resizeTO);
        this.resizeTO = setTimeout(function() {
          $(this).trigger('resizeEnd');
      }, 500);

  	});

    // facebook page feed
    $(window).bind('resizeEnd', function() {
        var fb_wrapper = $("#fb-wrapper").outerWidth();
        $("#fb-wrapper .fb-page").attr("data-width", fb_wrapper);
        $("#fb-wrapper .fb-page").data("width", fb_wrapper);
        FB.XFBML.parse();
    });

  	// IOS INPUT BUG FIX
    (function iOS_CaretBug() {
       var ua = navigator.userAgent,
       scrollTopPosition,
       iOS = /iPad|iPhone|iPod/.test(ua),
       iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

       // ios 11 bug caret position
       if ( iOS && iOS11 ) {

           $(document.body).on('show.bs.modal', function(e) {
               if ( $(e.target).hasClass('modal') ) {
                   // Get scroll position before moving top
                   scrollTopPosition = $(document).scrollTop();

                   // Add CSS to body "position: fixed"
                   $("body").addClass("iosBugFixCaret");
               }
           });

           $(document.body).on('hide.bs.modal', function(e) {
               if ( $(e.target).hasClass('modal') ) {
                   // Remove CSS to body "position: fixed"
                   $("body").removeClass("iosBugFixCaret");

                   //Go back to initial position in document
                   $(document).scrollTop(scrollTopPosition);
               }
           });
       }
    })();

    // CAROUFREDSEL SLIDER
	function caroufredsel_slider() {
		$(".cs-slider .slides").each(function(){
			var $this    = $(this);
			var ctrlPrev = $(this).closest(".cs-slider").find(".prev");
			var ctrlNext = $(this).closest(".cs-slider").find(".next");

			$this.carouFredSel({
				auto: {
					play: $this.data("auto"),
					timeoutDuration: $this.data("duration"),
				},
				start      : "random",
				width      : $this.data("cs-width"),
				circular   : $this.data("cs-circular"),
				infinite   : $this.data("cs-infinite"),
				height     : $this.data("cs-height"),
				responsive : $this.data("cs-responsive"),
				direction  : $this.data("cs-direction"),
				prev       : ctrlPrev,
				next       : ctrlNext,
				pagination : "."+$this.data("paginate"),
				scroll:{
					items: $this.data("item-scroll"),
					fx   : $this.data("effects"),
				},
				items:{
				  height : $this.data("item-height"),
				  width  : $this.data("item-width"),
				  visible: {
				    	min: $this.data("min"),
				    	max: $this.data("max")
				  },
				},
				swipe:true,
			});

			$this.touchwipe({
				wipeUp: function(e) {
				 	e.preventDefault();
				},
				wipeDown: function(e) {
				 	e.preventDefault();
				},
				wipeLeft: function(e) {
				 	$this.trigger("next", 1);
				},
				wipeRight: function(e) {
				 	$this.trigger("prev", 1);
				}
			});
		});
	}
});

    //offcanvas Menu
    function offcanvasMenu() {
        $('.burger-wrapper').on("click", function(){
            $(this).toggleClass('active');
            $('#sidepanel').toggleClass('active');
        });
    }

    // About Tabs
    function aboutTabs() {
        $('.tabs .cstm-col:first-child .img').addClass('active');
        $('.tabs .content:nth-child(5)').slideDown();
        $('.tabs .img').on('click', function(){
            $('.tabs .content').slideUp();
            $('.tabs .img').removeClass('active');
            // $(this).parent().find('.content').slideToggle();
            $(this).toggleClass('active');
            var tabID = $(this).parent().attr('id');
            tabID = tabID + '-content';
            // setTimeout(function(){
                $('.tabs').find('#' + tabID).slideToggle();
            // }, 500);
        });
    }

    // Treatments Tabs
    function treatmentTabs() {
        $('.treatments-tab .cstm-row:first-child .cstm-col:first-child .tab').addClass('active');
        $('.treatments-tab .cstm-row:first-child .cstm-col:first-child .tab').trigger( "click" );
        if($(window).width() >= 992) {
            // $('.treatments-tab .cstm-row:first-child .content:nth-child(4)').slideDown();
        } else if ($(window).width() < 992) {
            $('.treatments-tab .cstm-row:first-child .content:nth-child(2)').slideDown();
        }
        $('.treatments-tab .tab').on('click', function() {
            $('.treatments-tab .tab').removeClass('active');
            $('.treatments-tab .content').slideUp();
            $(this).toggleClass('active');
            var tabID = $(this).data('id');
            // setTimeout(function(){
                $('.treatments-tab').find('#' + tabID).slideToggle();
            // }, 500);
        });
    }

    //Primary Navigation
    function primaryNavigation() {
        $('.desktop-header .menu-item-has-children').on('click', function() {
            $(this).find('>.sub-menu').slideToggle();
        });
        $('.offcanvas-menu .menu-item-has-children').on('click', function() {
            $(this).find('>.sub-menu').slideToggle();
        });
    }


    // new tabs
    function _expandTabs(){
      $(".new-tabs .tab-item").each(function(){
        var $this = $(this);

        $(this).find(".img").on("click", function(){
          $(".new-tabs .tab-item").removeClass("active");
          $(this).closest(".tab-item").addClass("active");

          $('.hidden-content').css('display', 'none');
          $(this).closest('.tab-item').find('.hidden-content').css('display', 'block');

          $(this).closest('.tab-item').find('.hidden-content').css('top', $this.outerHeight() + 15);

          if ( Modernizr.mq('(max-width: 768px)') ) {
            $('.new-tabs .tab-item').css('margin-bottom', '15px');
          } else {
            $('.new-tabs .tab-item').css('margin-bottom', '2%');
          }
          $this.css('margin-bottom', $(this).closest('.tab-item').find('.hidden-content').outerHeight() + 30);
        });

        if ( $this.hasClass( "active" ) ) {
          $this.find('.hidden-content').css('top', $this.outerHeight() + 15);
          $this.css('margin-bottom', $(this).closest('.tab-item').find('.hidden-content').outerHeight() + 30);
        }
      });
    }

    //Sticky Header
    function stickyHeader() {
        var headerHeight = $('.new-mobile-header').outerHeight();
        var dheaderHeight = $('.desktop-header').outerHeight();
        if ($(window).scrollTop() <= 100) {
            if ( $('.new-mobile-header').hasClass('sticky') || $('.desktop-header').hasClass('sticky') ) {
                $('.new-mobile-header').removeClass('sticky');
                $('.desktop-header').removeClass('sticky');
            }
            $('main').css({
                'padding-top' : 0
            });
        } else {
            $('.new-mobile-header').addClass('sticky');
            // $('.desktop-header').addClass('sticky');
             if ($(window).width() <= 991) {
                $('main').css({
                    'padding-top' : headerHeight
                });
             } else {
                $('main').css({
                    // 'padding-top' : dheaderHeight
                });
             }
        }
    }


    function cf7formsubmit() {
        var sitelink = $('#header .site-logo a').attr('href');
        document.addEventListener( 'wpcf7mailsent', function( event ) {
            location = sitelink + '/thank-you/';
        }, false );
    }